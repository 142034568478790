<template>
  <div class="container">
      <img src="~@/assets/system/apply-kindergarten.jpeg" class="img" />
  </div>
</template>

<script>
export default {
  name: 'ApplyKindergarten'
};
</script>

<style lang="scss" scoped>
.container {
  background: #fff;
}
.img {
  width: 100vw;
}
</style>
